<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import device from '@@/bits/device'
import { __, n__ } from '@@/bits/intl'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzBadge, { OzBadgeColorScheme, OzBadgeSizePreset } from '@@/library/v4/components/OzBadge.vue'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import OzRelativeTime from '@@/library/v4/components/OzRelativeTime.vue'
import type { Library, WallCamelCase as Wall, Wall as WallSnakeCase } from '@@/types'
import { computed, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    wall: Wall | WallSnakeCase
    cardWidth: number
    wallTimestampType?: string
    xMoreButton?: boolean
    invisibleMoreButton?: boolean
    library?: Library
    canSelect?: boolean
    isSelected?: boolean
    href?: string
    displayName?: string
    isFaded?: boolean
    disabled?: boolean
  }>(),
  {
    wallTimestampType: 'updatedAt',
    xMoreButton: true,
    invisibleMoreButton: false,
    library: undefined,
    canSelect: false,
    isSelected: false,
    href: undefined,
    displayName: undefined,
    isFaded: false,
    disabled: false,
  },
)

const emit = defineEmits<{
  (name: 'more', wall: Wall | WallSnakeCase, event: PointerEvent): void
  (name: 'right-click', wall: Wall | WallSnakeCase, event: PointerEvent): void
  (name: 'select-card', wall: Wall | WallSnakeCase, event: MouseEvent | KeyboardEvent): void
  (name: 'aux-click', event: PointerEvent): void
  (name: 'click', event: Event): void
  (name: 'mouseenter', event: PointerEvent): void
  (name: 'clicked-when-disabled'): void
}>()

const isSafari = device.safari

const isFocused = ref<boolean>(false)
const isHoveringMoreButton = ref<boolean>(false)

const handleMoreClick = (event: PointerEvent): void => {
  emit('more', props.wall, event)
  trackEvent('Dashboard', 'Clicked More button on dashboard wall card')
}

const wallLabel = computed((): string => {
  if (props.disabled) return ''
  return props.href
    ? __('Go to %{wallTitle} padlet', { wallTitle: wallTitle.value })
    : __('Select %{wallTitle} padlet', { wallTitle: wallTitle.value })
})

const wallThumbnailTitle = computed((): string => {
  return __('%{wallTitle} padlet thumbnail', { wallTitle: wallTitle.value })
})

const wallTitle = computed((): string => {
  return props.wall.title || __('Untitled padlet')
})

const wallTimestamp = computed((): string => {
  if (props.wallTimestampType === 'updatedAt') {
    return 'updated_at' in props.wall ? props.wall.updated_at : props.wall.updatedAt
  }
  return props.wall[props.wallTimestampType]
})

const accountDisplayName = computed((): string => {
  if (props.displayName !== undefined) {
    return props.displayName
  }

  const displayName =
    'displayName' in props.wall.builder ? props.wall.builder.displayName : props.wall.builder.display_name
  return props.library ? props.library.name : displayName
})

const daysTillWallIsDeleted = computed((): number => {
  const daysLeft =
    'daysTillDeletion' in props.wall ? props.wall.daysTillDeletion : (props.wall as WallSnakeCase).days_till_deletion
  return daysLeft ?? 0
})

const daysTillDeletedDisplay = computed((): string => {
  return n__('%{num} day left', '%{num} days left', daysTillWallIsDeleted.value, { num: daysTillWallIsDeleted.value })
})

const wallDeletingSoon = computed((): boolean => {
  return daysTillWallIsDeleted.value <= 3
})

const xRelativeTimestamp = computed((): boolean => {
  return props.wallTimestampType !== 'trashedAt'
})

const xTemplate = computed((): boolean => {
  if ('isTemplate' in props.wall) {
    return props.wall.isTemplate === true
  }
  return false
})

const moreButtonTopOffset = computed((): number => {
  const wallThumbnailHeight = props.cardWidth / 2 // Since the wall thumbnail has an aspect ratio of 2, the height of the wall thumbnail is half of its width
  const gapBetweenWallThumbnailAndWallTitle = 12 // 12px = gap between thumbnail and wall name
  return wallThumbnailHeight + gapBetweenWallThumbnailAndWallTitle
})

const wallThumbnailPlaceholderColor = computed((): string | undefined => {
  if (isWallThumbnailLoaded.value) return undefined
  return 'dominantColorAsRgb' in props.wall.thumbnail
    ? props.wall.thumbnail.dominantColorAsRgb
    : props.wall.thumbnail.dominant_color_as_rgb
})

const showMoreMenu = (e: PointerEvent): void => {
  if (props.xMoreButton) {
    e.preventDefault()
    emit('right-click', props.wall, e)
    trackEvent('right-click', { target: 'dashboard-wall-card' })
  }
}

const handleWallThumbnailLoadingError = (e: Error): void => {
  // do nothing
  /**
   * #WONTFIX
   * Image load errors should be gone when they refresh the dashboard.
   * If users still don't see the image, they will probably email us with the padlet link, then we can get the image source by visiting the padlet
   *  Logging is error is not particularly helpful when there are so many of them.
   */
}

const isWallThumbnailLoaded = ref<boolean>(false)

const handleWallThumbnailLoadingSuccess = (): void => {
  isWallThumbnailLoaded.value = true
}

watch(
  () => props.cardWidth,
  () => {
    // Reset it to show the placeholder when resizing
    isWallThumbnailLoaded.value = false
  },
)

const handleSelect = (event: MouseEvent | KeyboardEvent | PointerEvent): void => {
  if (props.disabled) {
    emit('clicked-when-disabled')
    return
  }
  // If the user performs a ctrl/command/shift + click, we select the card
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    handleMultiselect(event as MouseEvent)
    return
  }

  emit('click', event)
}

const handleMultiselect = (event: MouseEvent): void => {
  if (props.disabled) {
    return
  }
  if (props.canSelect) {
    emit('select-card', props.wall, event)
  }
}

const handleKeydownSpace = (event: KeyboardEvent): void => {
  if (props.disabled) {
    return
  }
  // When OzBaseButton is rendered as a button, the space button is used to select/un-select the padlet
  if (props.canSelect) {
    emit('select-card', props.wall, event)
    return
  }

  emit('click', event)
}

const handleAuxButtonClick = (event: PointerEvent): void => {
  if (props.disabled) {
    return
  }
  // See https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
  const isMousewheelBeingClicked = event.button === 1
  if (isMousewheelBeingClicked) {
    emit('aux-click', event)
  }
}
</script>

<script lang="ts">
export default {}
</script>

<template>
  <div class="relative w-min">
    <OzBaseButton
      :id="`${wall.namespace}_${wall.name}WallCardButton`"
      :class="[
        'flex',
        'flex-col',
        'gap-3',
        !href && 'p-0',
        isFocused && [
          'relative outline-none outline-offset-0',
          'after:content-[\'\'] after:absolute after:-inset-1 after:rounded-xl after:outline after:outline-[3px] after:outline-grape-500 dark:after:outline-canary-500',
          '*:z-[1]',
        ],
        'group',
        isSelected
          ? {
              'bg-[#F5F0FF] dark:bg-[#302917] rounded-lg': true,
              'outline outline-4 outline-[#f5f0ff] dark:outline-[#302917] after:content-[\'\'] after:absolute after:-inset-1 after:rounded-xl after:outline after:outline-2 after:outline-grape-500 dark:after:outline-canary-500': true,
            }
          : {
              'bg-transparent': !isHoveringMoreButton,
              'bg-button-light dark:bg-button-dark rounded-lg': isHoveringMoreButton,
              'outline outline-4 outline-button-light dark:outline-button-dark': isHoveringMoreButton,
              // Hover state
              'hhover:rounded-lg hhover:bg-button-light dark:hhover:bg-button-dark': true,
              'hhover:outline hhover:outline-4 hhover:outline-button-light dark:hhover:outline-button-dark': true,
              // Active state
              'hactive:rounded-lg hactive:bg-button-light hactive:dark:bg-button-dark': true,
              'hactive:outline hactive:outline-4 hactive:outline-button-light dark:hactive:outline-button-dark': true,
            },
      ]"
      :style="{
        maxWidth: `${cardWidth}px`,
      }"
      :title="wallLabel"
      :aria-label="wallLabel"
      :data-testid="`${isSelected ? 'wallCardSelected' : 'wallCardUnselected'}`"
      :href="href"
      @click.prevent.stop="handleSelect"
      @mousedown.prevent.stop
      @auxclick.prevent.stop="handleAuxButtonClick"
      @contextmenu="showMoreMenu"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @keydown.enter.stop.prevent="handleSelect"
      @keydown.space.stop.prevent="handleKeydownSpace"
      @mouseenter.prevent.stop="$emit('mouseenter', $event)"
    >
      <span class="bg-transparent relative">
        <div
          class="absolute inset-0 rounded-lg bg-gradient-to-b from-[rgba(29,29,29,0)_36.98%] to-[rgba(29,29,29,0.6)] mix-blend-soft-light z-10 hidden hover-hover:group-hover:block"
        />
        <ImageThumbnail
          :class="['rounded-lg max-w-full', isFaded && 'opacity-30']"
          :src="wall.thumbnail.url"
          :original-image-width="wall.thumbnail.width || 256"
          :original-image-height="wall.thumbnail.height || 128"
          :width="cardWidth"
          :aspect-ratio="2"
          :title="wallThumbnailTitle"
          alt=""
          :placeholder-color-value="wallThumbnailPlaceholderColor"
          :placeholder-color="'random'"
          :fallback-to-placeholder="true"
          :process-image="true"
          @error="handleWallThumbnailLoadingError"
          @load="handleWallThumbnailLoadingSuccess"
        />
        <span class="flex absolute top-0 ms-2 mt-2 h-4 space-s-2">
          <OzBadge
            v-if="wall.frozen"
            :data-testid="`${wall.title}FrozenBadge`"
            :class="['text-light-text-100 bg-teal-600']"
            :text="__('Frozen')"
            :size-preset="OzBadgeSizePreset.Tiny"
            :color-scheme="OzBadgeColorScheme.Bare"
          >
            <template #startAdornment> <OzIcon name="freeze" :size="10" class="pe-0.5" /> </template
          ></OzBadge>
          <OzBadge
            v-if="xTemplate"
            :data-testid="`${wall.title}TemplateBadge`"
            :class="['text-light-text-100 bg-teal-600']"
            :text="__('Template')"
            :size-preset="OzBadgeSizePreset.Tiny"
            :color-scheme="OzBadgeColorScheme.Bare"
          />
        </span>
      </span>

      <span class="flex w-full">
        <span class="flex-1 space-y-0.5 ms-1.5 mb-1 min-w-0">
          <span
            :class="[
              isFaded ? 'text-dark-text-300 dark:text-light-text-300' : 'text-dark-text-100 dark:text-light-text-100',
              'group-active:text-grape-500 dark:group-active:text-canary-500',
              'text-body-small text-start',
              'font-semibold',
              'line-clamp-2',
              'break-word-anywhere',
              xMoreButton && 'me-7', // To account for the more button to prevent stacking of the wall title on the more button
            ]"
          >
            {{ wallTitle }}
          </span>
          <span
            :class="[
              'text-body-extra-small whitespace-nowrap flex me-2',
              'text-dark-text-200 dark:text-light-text-200',
            ]"
          >
            <span class="truncate">{{ accountDisplayName }}</span>
            <span class="text-dark-text-300 dark:text-light-text-300"> &nbsp;•&nbsp; </span>
            <OzRelativeTime v-if="xRelativeTimestamp" :datetime="wallTimestamp" dateformat="short" />
            <span v-else :class="[wallDeletingSoon && 'text-danger-100']">{{ daysTillDeletedDisplay }}</span>
          </span>
        </span>
      </span>
    </OzBaseButton>

    <OzPlainButton
      v-if="xMoreButton"
      :id="`${wall.namespace}_${wall.name}WallCardMoreButton`"
      :data-testid="`${wallTitle}MoreButton`"
      :class="['-mt-1 ms-1 -me-1 absolute end-0', invisibleMoreButton && 'invisible']"
      :style="{ top: `${moreButtonTopOffset}px` }"
      :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
      :size-preset="OzPlainButtonSizePreset.H28px"
      :title="__('More padlet actions for %{wallTitle}', { wallTitle })"
      :aria-label="__('More padlet actions for %{wallTitle}', { wallTitle })"
      @click.prevent.stop="handleMoreClick"
      @mouseenter.stop="isHoveringMoreButton = true"
      @mouseleave.stop="isHoveringMoreButton = false"
    >
      <OzIcon :size="20" name="more_vertical_thin" />
    </OzPlainButton>
  </div>
</template>
